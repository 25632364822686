import { ENABLE_ANNOUNCEMENT_MODE } from '../constants/feature-flags';
import useQueryString from './useQueryString';

/**
 * Custom hook to return GuestName
 * @return {string}
 */
function useGroupLink() {
  if (!ENABLE_ANNOUNCEMENT_MODE) return true;

  const { getQueryValue } = useQueryString();
  const queryType = getQueryValue('type') === 'invitation';
  const queryPartner =
    getQueryValue('partner') === '1' ||
    getQueryValue('partner') === '2' ||
    getQueryValue('partner') === '3';

  return Boolean(queryPartner && queryType);
}

export default useGroupLink;
